<template>
  <div v-if="showConditionalField" v-html="displayText" />
</template>

<script>
import conditionalField from '@/js/mixins/conditionalField.js';

export default {
  name: 'DisplayText',
  mixins: [conditionalField],
  props: {
    name: {
      type: String,
      required: true,
    },
    displayText: {
      type: String,
      required: true,
    },
  },
};
</script>
