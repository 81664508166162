<template>
  <div class="c-sum-row" data-test="sum-row">
    <div class="c-sum-row__sum" data-test="sum-number">
      {{ asCurrency(total) }}
    </div>
    <div class="c-sum-row__label">
      Total Direct Costs
    </div>
  </div>
</template>

<script>
import {asCurrency, toNumber} from '@/js/utils/stringUtil.js';

export default {
  name: 'SumRow',
  setup() {
    return {asCurrency};
  },
  data() {
    return {
      inputs: [],
      total: 0,
    };
  },
  mounted() {
    this.inputs = Object.values(this.$root.$refs);
    // add event listener so that we things update when the refs are changed.
    // we want things to change when there is an input
    this.inputs.forEach((ref) => {
      ref.$el.addEventListener('input', this.updateTotal);
    });
    // manually call the update total so that the sum is correct on initial load
    this.updateTotal();
  },
  methods: {
    updateTotal() {
      this.total = this.inputs.reduce((prevSum, currentVal) => prevSum + toNumber(currentVal.localValue), 0);
    },
  },
};
</script>

<docs lang="md">
This component is used in SIMPLE FORMS ONLY.
This component assumes that you will have X number of number inputs that are siblings to this component
```
<number-input />
<number-input />
<sum-row />
```

This component will get the number inputs by using template refs. We need to wait for the Number Inputs to render
so in the mounted function, after the components have rendered, we  add a vanilla event listener to the input event
of the root element of the number inputs. When the number input recives an input event
the updateTotal function will run and the total
</docs>
