<template>
  <base-task-button
    ref="button"
    :btn-class="btnClass"
    :task-payload="formData"
    :task-url="taskUrl"
    progress-description="The command is running, please wait."
    override-click-handler
    @click="handleClick"
    @success="onSuccess"
    @cancel="onCancel"
    @failure="onFailure"
  >
    <template #default>
      Run
    </template>
  </base-task-button>
</template>

<script>
import {error as errorPrompt, success} from '@/js/utils/sweetAlert2.js';
import BaseTaskButton from '@/js/components/BaseTaskButton.vue';
import {parseFormErrorsGeneric} from '@/js/utils/api.js';
import ConfirmSave from '@/js/modules/confirmSave.js';

export default {
  name: 'TaskButtonRunCommand',
  components: {BaseTaskButton},
  inject: ['csrfToken', 'urls', 'api'],
  props: {
    taskUrl: {
      type: String,
      default: '',
      required: false,
    },
    btnClass: {
      type: [String, Object],
      default: undefined,
      required: false,
    },
    successRedirectUrl: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      formData: {},
      form: undefined,
    };
  },
  mounted() {
    // This is a non-standard/non-vue way of grabbing the data from a form
    // Do not copy this code unless you know what you're doing
    this.form = this.$refs.button.$el.form;
  },
  methods: {
    async handleClick() {
      this.formData = new FormData(this.form);

      try {
        const {data} = await this.api.post(this.taskUrl, this.formData),
              shouldSubmit = await ConfirmSave.confirmIfNeeded(data);
        if (shouldSubmit === false) {
          return;
        }
      } catch (e) {
        this.onFailure(e);
      }

      this.formData.append('_is_confirmed', '1');
      await this.$nextTick();
      this.$refs.button.launchBackgroundTask();
    },
    async onSuccess(message) {
      await success({html: message.success});
      window.location = this.successRedirectUrl;
    },
    onFailure(failure) {
      const errorResponse = failure.errors || failure,
            {formFieldErrors, formAlertMessages} = parseFormErrorsGeneric(errorResponse, false);
      errorPrompt({title: 'Command Failed', html: `${Object.values(formFieldErrors).join('<br />')}${formAlertMessages.map((error) => error.message).join('<br />')}`});
    },
    onCancel() {
      return 'cancel';
    },
  },
};
</script>
