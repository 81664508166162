import UppyCore from '@uppy/core';
import AwsS3 from '@uppy/aws-s3';
import generateFileID from '@uppy/utils/lib/generateFileID';
import {v4 as uuidv4} from 'uuid';
import mime from 'mime/lite';
import Changes from '@/js/modules/changes.js';
import {imageMaxSize, uploadType} from '../enums/api.js';
import {objectToURLSearchParams} from './api.js';
import {error as errorPrompt, snackbar} from './sweetAlert2.js';

/**
 * @see image_variants.py::get_variant_options
 * @param {Object} Upload - accepts either baseOptions or base_options
 * @param {Object} variantOptions - additional overrides for variant options
 * @return {string}
 */
export function buildVariantSrc({id, base_options: baseOptions}, variantOptions) {
  const {croppie_points: croppiePoints, croppie_zoom: croppieZoom, ...apibaseOptions} = baseOptions, // get rid of ones we don't need
        paramString = objectToURLSearchParams({
          redirect: 1,
          ...apibaseOptions,
          ...variantOptions,
        }).toString();

  return `/api/uploads/image-variant/${id}/?${paramString}`;
}

/**
 * @param {String|Int} id - an uploadId - i.e. Uppy.File.meta.uploadId
 */
export function getDownloadUrl(id) {
  return `/uploads/download/${id}/`;
}

/**
 * USAGE NOTES:
 * Setup of this class might look like this:
 *  ```
 *  async created(){
 *    this.api = new API(csrfToken);
 *    this.uppy = Uppy({
 *      api: this.api,
 *      relatedEntity: {
 *        url: this.urls.objectDetailUrl,
 *        field: this.name,
 *      },
 *      autoProceed: this.autoProceed,
 *      restrictions: this.restrictions,,
 *    });
 *    await this.uppy.loadExistingFiles({uploadIds: this.existingUploadIds});
 *    console.log('files loaded!');
 *  }
 *  ```
 *
 *
 * WARNING!!!!!
 * If you are using this in a vue component and passing to a child component,
 * you MUST instantiate in the created hook so its not null when the child component is created
 *
 * @see UppyFile
 * File.name is the AWS filename
 * File.meta.name is the pretty name you probably want - use Uppy.displayName shortcut
 *
 * @parm {{api: String, relatedEntity?: {url: String, field: String}, onComplete?: Function, restrictions: Object}}
 * Note relatedEntity is the backend model which contains an upload field
 * Note onComplete is optional and will completely override listener for complete in this class
 */
class Uppy extends UppyCore {
  constructor({
    api, relatedEntity, restrictions, onComplete, ...props
  }) {
    super({
      ...props,
      // @see https://uppy.io/docs/uppy/#restrictions
      restrictions: {
        maxFileSize: imageMaxSize,
        // minFileSize
        // maxNumberOfFiles
        // minNumberOfFiles
        // allowedFileTypes
        ...restrictions,
        // any restriction transformations below
        ...(restrictions && {
          allowedFileTypes: restrictions.allowedFileTypes.map((type) => (type[0] === '.' ? type : `.${type}`)), // allow for .ext or ext, comes from backend
        }),
      },
    })
      .use(AwsS3, {
      // Get params for S3 POST request
        getUploadParameters: async (file) => {
          const {
            baseOptions, uuid, alt, name,
          } = file.meta,
                {data: [data]} = await api.post('/api/uploads/get-presigned-post-url/', {file_names: [file.name]}),
            // hijack this to ensure our upload finishes before the success events fire
                {data: {id: uploadId}} = await api.post('/api/uploads/upload/', {
                  name,
                  uuid,
                  alt_text: alt,
                  size: file.size,
                  ext: file.extension,
                  path: data.fields.key,
                  is_uploaded: true,
                  is_orphaned: false,
                  base_options: baseOptions,
                });

          this.setFileMeta(file.id, {uploadId}); // save upload id in meta for later
          return {
            url: data.url,
            method: 'post',
            fields: data.fields,
            headers: {},
          };
        },
      })
      .on('upload', () => {
        Changes.incrementInFlight();
      })
      /**
       * Sets the following
       * - file.name = UUID.ext
       * - file.meta.uuid = UUID
       */
      .on('file-added', (file) => {
        // setup single file
        if (this.isSingleFile) {
          this.singleFileId = file.id;
        }

        // set UUID
        if (file.meta.uuid) return; // already exists - bail
        const uuid = uuidv4();
        file.name = `${uuid}.${file.extension}`;
        this.setFileMeta(file.id, {uuid});
      })
      .on('restriction-failed', async (file, error) => {
        try {
          await errorPrompt({text: `Error: ${error.message}`});
        } catch (e) {
          // Swallow the error bc Sweet alert doesn't gracefully handle multiple modals fired in a row
          // i.e. if you try to upload 3 files and the first one succeeds but others fail bc of 1 file restriction
          // @see https://canopyllc.slack.com/archives/C02H3947VU4/p1676594089549129
        }
      })
      /**
       * Toggle the is_uploaded flag on the upload that failed to false
       */
      .on('upload-error', (file) => {
        if (!file.meta.uploadId) return; // do nothing, upload wasn't even created
        // A failure on this PATCH will not be caught... I blame Trump
        this.api.patch(`/api/uploads/upload/${file.meta.uploadId}/`, {is_uploaded: false, is_orphaned: true});
      })
      /**
       * Default handler for onComplete event (overrideable if you pass onComplete)
       * 1. If uploads failed, show an error message
       * 2. If upload succeeds, send PATCH to link upload ids to the related Entity that has an upload field on the model.
       *    Show success message once that is done
       * 3. If PATCH fails, show error message.
       */
      .on('complete', async ({failed, successful}) => {
        Changes.decrementInFlight();
        // allow override
        if (this.onComplete) {
          return this.onComplete({failed, successful});
        }
        if (failed.length > 0) {
          errorPrompt({text: 'File upload failed. Please try again.'});
          failed.forEach((file) => {
            this.removeFile(file.id);
          });
        }
        if (successful.length > 0) {
          try {
            await this.saveUploadIds();
            snackbar({text: 'File upload successful!'});
          } catch (error) {
            errorPrompt({text: error.message || error});
            // @todo - we could retry here or something? Might be best just to remove the file
            // we can't actually DELETE so would leave an unlinked upload record in db potentially
          }
        }
        return true;
      });

    this.api = api;
    this.relatedEntity = relatedEntity;
    this.onComplete = onComplete;
    this.isSingleFile = restrictions && restrictions.maxNumberOfFiles === 1;
    this.singleFileId = null;
    this.emptyFile = {
      meta: {
        baseOptions: {},
      },
    };
  }

  /**
   * @return {boolean}
   */
  get hasFilesToRetry() {
    return this.getFiles().filter((file) => file.error).length > 0;
  }

  /**
   * @return {boolean}
   */
  get hasFilesAwaitingUpload() {
    return this
      .getFiles()
      .filter((file) => !file.meta.isPreExistingFile && !file.progress.uploadComplete).length > 0;
  }

  /**
   * @return {boolean}
   */
  get hasFiles() {
    return this.getFiles().length > 0;
  }

  /**
   * Get all the upload ids for all files
   * @return {Number[]}
   */
  get uploadIds() {
    return this.getFiles().map((file) => file.meta.uploadId);
  }

  getBaseOption(optionKey, fileId) {
    const file = fileId ? this.getFile(fileId) : this.getSingleFile();
    if (file && file.meta.baseOptions && file.meta.baseOptions[optionKey]) {
      return file.meta.baseOptions[optionKey];
    }
    return null;
  }

  getMeta(key, fileId) {
    const file = fileId ? this.getFile(fileId) : this.getSingleFile();
    if (!file || !file.meta[key]) return '';
    return file.meta[key];
  }

  /**
   * If its a single file input, return the file
   * @return {Uppy.UppyFile}
   */
  getSingleFile() {
    return this.getFile(this.singleFileId);
  }

  /**
   * Cast a Uppy.File to django Upload Model
   * @param fileId - optional. If omitted assumes single file
   * @return {{base_options: *, alt_text: *, name: string, id: *}}
   */
  toUpload(fileId) {
    const file = fileId ? this.getFile(fileId) : this.getSingleFile();
    return {
      id: file.meta.uploadId,
      name: file.meta.name,
      alt_text: file.meta.alt,
      base_options: file.meta.baseOptions,
    };
  }

  /**
   * This method persists all the current uploadIds to the upload field on the relatedEntity
   * @return {Promise}
   */
  saveUploadIds() {
    if (this.relatedEntity?.url === undefined) return Promise.resolve();
    return this.api.patch(this.relatedEntity.url, {[this.relatedEntity.field]: this.uploadIds});
  }

  /**
   * Extend Uppy.removeFile to also unlink the file by calling `saveUploadIds`
   * @return {Promise}
   */
  removeFileAndSync(fileID, sync = true) {
    super.removeFile(fileID, 'removed-by-user');
    if (sync === false) return Promise.resolve();
    return this.saveUploadIds();
  }

  /**
   * Create the source url for a variant image. i.e. <img src="variantSrc()">
   *
   * The default behavior is to assume this is a single file input if fileId is not given.
   * Load the image with all the variant options given (quality, zoom, crop etc)
   *
   * @param {String|undefined} fileId - optional
   * @param {Object} options - optional dictionary of variant options to override - most common being size: '300x0' or whatever
   * @return {String}
   */
  variantSrc({fileId, options} = {}) {
    const file = fileId ? this.getFile(fileId) : this.getFile(this.singleFileId);

    if (!file || !file.meta.baseOptions) return '';

    /** @see getCropForApi */
    return buildVariantSrc({
      id: file.meta.uploadId,
      base_options: file.meta.baseOptions,
    }, options);
  }

  /**
   * Given a list of uploadIds, load them into this uppy instance
   *
   * @param {Number[]} uploadIds
   * @param {String} maxImageSize - considered the full size image in this context, uncropped, WxH dimensions
   *  ^^ NOTE MUST BE BIGGER THAN ThumbnailGenerator.thumbnailWidth/thumbnailHeight
   *
   * NOTES ON THUMBNAIL/PREVIEWS
   * If you have a `Uppy.use(ThumbnailGenerator, {...});` statement somewhere called against this instance,
   * a thumbnail is automatically created whenever uppy.addFile() is invoked (either by code or user).
   * The thumbnail is stored at `File.preview` and its size is thumbnailWidth x thumbnailHeight based on options passed to ThumbnailGenerator
   *
   * If you want to use a different size preview (full uncropped image) somewhere else you could do:
   * ```
   *    const [imageUrl] = await this.api.post('/api/uploads/get-presigned-url/', {upload_ids: [uploadId]});
              {data: blob} = await this.api.get(imageUrl),
   *          preview = URL.createObjectURL(blob); // new preview URL
   * ```
   *
   * If you want to get a specific size for the preview you would have to call variant API instead
   * ```
   *    const {data: blob} = await this.api.get(`/api/uploads/image-variant/${id}/?size=${previewSize}&redirect=1`, {responseType: 'blob'}),
   *          preview = URL.createObjectURL(blob); // new preview URL
   * ```
   *
   * If you want to overwrite the current preview within Uppy, do this (be careful of unforseen consequenses!)
   * (note "this" = uppy instance)
   * ```
   *    const preview = <new preview URL>;
   *    this.setFileState(file.id, {preview});
   *    this.emit('thumbnail:generated', file, preview);
   * ```
   * @return {PromiseConstructor}
   */
  async loadExistingFiles({uploadIds, maxImageSize = '11x11'}) {
    // eslint-disable no-return-await
    // this maps over all upload ids in PARALLEL (Array.map() doesn't care if your function is async),
    // but inside the map function each upload id must resolve first the GET upload and then the S3 download calls respectively before resolving
    const existingFiles = await Promise.all(uploadIds.map(async (id) => {
      let blob,
          upload = {name: 'Error loading file.txt'};
      try {
        ({data: upload} = await this.api.get(`/api/uploads/upload/${id}/`));
        if (upload.type === uploadType.IMAGE) {
          // download image from S3
          ({data: blob} = await this.api.get(`/api/uploads/image-variant/${id}/?size=${maxImageSize}&redirect=1`, {responseType: 'blob'}));
        } else {
        // non images we don't need actual image
          blob = new Blob([new ArrayBuffer(upload.size)], {type: mime.lookup(upload.ext)});
        }
      } catch (error) {
        this.log(error);
        this.info(error.message || 'An error occurred while loading your files.', 'error', 10000);
        // same as non image code above - unknown type
        blob = new Blob([new ArrayBuffer(0)], {type: 'text/plain'});
      }
      return {blob, upload};
    }));
    // eslint-enable no-return-await

    existingFiles.forEach(({blob, upload}) => {
      // Add each file to Uppy
      // Check if file exists, if it does, append the current date to avoid errors
      const tempFileId = generateFileID({
        name: upload.name,
        type: blob.type,
        data: blob,
        meta: {
          uploadId: upload.id,
          uuid: upload.uuid,
          name: upload.name,
          alt: upload.alt_text,
          isPreExistingFile: true,
          baseOptions: upload.base_options,
        },
      }),
            doesFileExist = this.checkIfFileAlreadyExists(tempFileId),
            newFileName = `${upload.name.substring(0, upload.name.lastIndexOf('.'))}_${Date.now()}.${upload.ext}`,
            fileId = this.addFile({
              name: doesFileExist ? newFileName : upload.name,
              type: blob.type,
              data: blob,
              meta: {
                uploadId: upload.id,
                uuid: upload.uuid,
                name: doesFileExist ? newFileName : upload.name,
                alt: upload.alt_text,
                isPreExistingFile: true,
                baseOptions: upload.base_options,
              },
            });
      // Mark as already uploaded - doesn't work if you do in addFile for some reason
      // @see https://github.com/transloadit/uppy/issues/1112#issuecomment-554575409
      this.setFileState(fileId, {progress: {uploadComplete: true, uploadStarted: true}});
    });

    return existingFiles.map(({upload}) => upload);
  }
}

/**
 * Uppy factory - just to stay consistent with the Uppy library signature
 * @param args - all args accepted by uppy are allowed here
 * @param api - instance of the api (usually this.$store.api)
 * @return {UppyCore}
 */
export default function(...args) {
  return new Uppy(...args);
}
