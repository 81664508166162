<template>
  <div
    class="c-input"
    :class="wrapperClass"
    data-test="text-input"
  >
    <div class="c-input__inner">
      <label
        class="form-label c-input__label"
        :class="{required, 'visually-hidden': hideLabel, ...labelClass}"
        :for="fieldId"
        v-html="fieldLabel"
      />
      <input
        :id="fieldId"
        ref="input"
        type="file"
        :name="name"
        :value="localValue"
        :placeholder="placeholder"
        :disabled="disabled"
        :class="inputClass"
        class="form-control c-input__input"
        :aria-describedby="`${fieldId}-help-text`"
        @keydown.enter="onEnter"
        @input="onInput"
        @focus="onFocus"
        @blur="onBlur"
      >
      <div v-if="helpText" :id="`${fieldId}-help-text`" class="form-text c-input__help-text">
        {{ helpText }}
      </div>
    </div>
    <input-errors v-if="!hideErrors" :errors="errors" data-test="text-input-error" />
  </div>
</template>

<script>
import {ref} from 'vue';
import formInput from '@/js/mixins/formInput.js';
import conditionalField from '@/js/mixins/conditionalField.js';
import InputErrors from '@canopyllc/roots/components/InputErrors.vue';
import {useFocus} from '@/js/composables/forms/useFocus.js';

export default {
  name: 'LegacyFileInput',
  components: {InputErrors},
  mixins: [formInput, conditionalField],
  setup(props, {emit}) {
    const input = ref(null),
          {
            onBlur, onFocus, isFocused,
          } = useFocus({emit, autofocus: props.autofocus, inputRef: input});

    return {
      onBlur,
      onFocus,
      isFocused,
      input,
    };
  },
};

</script>

<docs lang="md">
This is a legacy type file input, which does NOT upload directly to S3.

DO NOT USE THIS UNLESS YOU KNOW WHAT YOURE DOING
</docs>
