import API from '@/js/utils/api.js';
import Uppy from '@/js/utils/uppy.js';

export default {
  inject: ['csrfToken', 'urls'],
  props: {
    name: {
      type: String,
      required: true,
    },
    autoProceed: {
      type: Boolean,
    },
    restrictions: {
      type: Object,
      default: () => ({maxNumberOfFiles: 1}),
    },
    existingUploadIds: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      uppy: null,
      api: null,
    };
  },
  created() {
    if (!this.urls.objectDetailUrl) {
      throw new Error('The following urls are required: objectDetailUrl');
    }
    this.api = new API(this.csrfToken);
    this.uppy = Uppy({
      api: this.api,
      relatedEntity: {
        url: this.urls.objectDetailUrl,
        field: this.name,
      },
      autoProceed: this.autoProceed,
      restrictions: this.restrictions,
      ...(this.onComplete ? {onComplete: this.onComplete} : {}), // optionally define an onComplete method to override listener
      ...(this.onBeforeFileAdded ? {onBeforeFileAdded: this.onBeforeFileAdded} : {}), // optionally define an onComplete method to override listener
    });
    this.uppy.loadExistingFiles({uploadIds: this.existingUploadIds});
  },
};
