<template>
  <div>
    <h4 v-if="showConditionalField" class="c-head c-head--section">
      {{ heading }}
    </h4>
    <text-area-input v-bind="$attrs" />
  </div>
</template>

<script>

import conditionalField from '@/js/mixins/conditionalField.js';
import TextAreaInput from '@canopyllc/roots/components/forms/TextareaInput.vue';

export default {
  name: 'TextAreaWithHeadingInput',
  components: {TextAreaInput},
  mixins: [conditionalField],
  props: {
    heading: {
      type: String,
      default: '',
      required: false,
    },
  },
  data() {
    return {
      // This is required for the conditionalField mixin - needs a name
      name: this.$attrs.name,
    };
  },
};

</script>
