import {ref, onMounted, nextTick} from 'vue';

/**
 * This is a composable function that is used to give focus functionality to form components
 * it returns an object with the following properties: isFocused, focus, onFocus, onBlur
 * @param {object: {emit: (function(): void), autofocus: boolean, inputRef: Ref}} options the options for the composable
 * @param emit the emit function from the component
 * @param autofocus the autofocus prop from the component, if true
 * @param inputRef the ref from the component. this should be the input that will be focused
 * @returns {{onBlur: (function(): void), focus: (function(): void), isFocused: *, onFocus: (function(): void)}}
 */
export function useFocus({emit, autofocus = false, inputRef = null} = {}) {
  // Using $refs is an exception not the rule.
  // https://vuejs.org/v2/guide/components-edge-cases.html#Accessing-Child-Component-Instances-amp-Child-Elements
  const isFocused = ref(false),
        // Manually triggers a focus when autofocus is true
        focus = () => {
          // if ref has a value try to focus it
          // check if the ref has a focus method, if not it is probably a select component
          inputRef.value.focus();
        },
        onFocus = () => {
          isFocused.value = true;
          emit('focus');
        },
        onBlur = () => {
          isFocused.value = false;
          emit('blur');
        };
  onMounted(async () => {
    if (autofocus === true) {
      // if ref is not set yet, wait for nextTick
      if (!inputRef.value) {
        await nextTick();
      }
      focus();
    }
  });

  return {
    isFocused,
    focus,
    onFocus,
    onBlur,
  };
}
